import './App.css';
import { main_component } from "./js/main.js";
import { useEffect } from 'react';

const App = () => {

  useEffect(() => {
    new main_component.HackNSlashDemo();
  }, []);

  return (
    <div className="container" id="container">
    <div className="ui">
      <div className="quest-journal-layout">
        <div className="quest-journal" id="quest-journal">
        </div>
      </div>
    </div>
    <div className="ui">
      <div className="quest-ui-layout">
        <div className="quest-ui" id="quest-ui">
          <div className="quest-text-title" id="quest-text-title"></div>
          <div className="quest-text" id="quest-text"></div>
        </div>
      </div>
    </div>
    <div className="ui">
      <div className="icon-ui">
        <div className="icon-bar" id="icon-bar">
          <div className="icon-bar-item" id="icon-bar-stats" style={{backgroundImage: "url('./resources/icons/ui/skills.png')"}}></div>
          <div className="icon-bar-item" id="icon-bar-inventory" style={{backgroundImage: "url('./resources/icons/ui/backpack.png')"}}></div>
          <div className="icon-bar-item" id="icon-bar-quests" style={{backgroundImage: "url('./resources/icons/ui/tied-scroll.png')"}}></div>
        </div>
      </div>
    </div>
    <div className="ui">
      <div className="stats" id="stats">
        <div className="stats-inner">
          <div className="stats-title">Statistics</div>
          <div className="stats-row">
            <div className="stats-tooltip">Strength
              <div className="stats-tooltiptext">How strong you are, affects how much damage you do. So blah blah if you're doing stuff then its stronger or whatever, the damage is up. This is text to show the tooltip.</div>
            </div>
            <div id="stats-strength">0</div>
          </div>
          <div className="stats-row">
            <div className="stats-tooltip">Wisdomness
              <div className="stats-tooltiptext">Wisdom is the guage of something to do with wisdom in the game because wisdom is important and wisdom is wise to wisdoming.</div>
            </div>
            <div id="stats-wisdomness">0</div>
          </div>
          <div className="stats-row">
            <div className="stats-tooltip">Benchpress
              <div className="stats-tooltiptext">Gotta have a good bench to be a warrior or something.</div>
            </div>
            <div id="stats-benchpress">0</div>
          </div>
          <div className="stats-row">
            <div className="stats-tooltip">Curl
              <div className="stats-tooltiptext">The ultimate expression of strength, this affects literally everything in your life.</div>
            </div>
            <div id="stats-curl">0</div>
          </div>
          <div className="stats-row">
            <div className="stats-tooltip">XP
              <div className="stats-tooltiptext">How much xp you've accumulated by killing things for xp. Get enough and you'll gain a level or something.</div>
            </div>
            <div id="stats-experience">0</div>
          </div>
        </div>
      </div>
    </div>
    <div className="ui">
      <div className="health-ui">
        <div className="health-bar" id="health-bar"></div>
      </div>
    </div>
    <div className="ui">
      <div className="inventory" id="inventory">
        <div className="inventory-inner">
          <div className="inventory-title">Inventory</div>
          <div className="inventory-row">
            <div className="inventory-column">
              <div className="inventory-item" id="inventory-equip-1" draggable="true"></div>
              <div className="inventory-item" id="inventory-equip-2" draggable="true"></div>
              <div className="inventory-item" id="inventory-equip-3" draggable="true"></div>
              <div className="inventory-item" id="inventory-equip-4" draggable="true"></div>
            </div>
            <div className="inventory-character"></div>
            <div className="inventory-column">
              <div className="inventory-item" id="inventory-equip-5" draggable="true"></div>
              <div className="inventory-item" id="inventory-equip-6" draggable="true"></div>
              <div className="inventory-item" id="inventory-equip-7" draggable="true"></div>
              <div className="inventory-item" id="inventory-equip-8" draggable="true"></div>
            </div>
          </div>
          <div className="inventory-row">
            <div className="inventory-item" id="inventory-1" draggable="true"></div>
            <div className="inventory-item" id="inventory-2" draggable="true"></div>
            <div className="inventory-item" id="inventory-3" draggable="true"></div>
            <div className="inventory-item" id="inventory-4" draggable="true"></div>
            <div className="inventory-item" id="inventory-5" draggable="true"></div>
            <div className="inventory-item" id="inventory-6" draggable="true"></div>
            <div className="inventory-item" id="inventory-7" draggable="true"></div>
            <div className="inventory-item" id="inventory-8" draggable="true"></div>
          </div>
          <div className="inventory-row">
            <div className="inventory-item" id="inventory-9" draggable="true"></div>
            <div className="inventory-item" id="inventory-10" draggable="true"></div>
            <div className="inventory-item" id="inventory-11" draggable="true"></div>
            <div className="inventory-item" id="inventory-12" draggable="true"></div>
            <div className="inventory-item" id="inventory-13" draggable="true"></div>
            <div className="inventory-item" id="inventory-14" draggable="true"></div>
            <div className="inventory-item" id="inventory-15" draggable="true"></div>
            <div className="inventory-item" id="inventory-16" draggable="true"></div>
          </div>
          <div className="inventory-row">
            <div className="inventory-item" id="inventory-17" draggable="true"></div>
            <div className="inventory-item" id="inventory-18" draggable="true"></div>
            <div className="inventory-item" id="inventory-19" draggable="true"></div>
            <div className="inventory-item" id="inventory-20" draggable="true"></div>
            <div className="inventory-item" id="inventory-21" draggable="true"></div>
            <div className="inventory-item" id="inventory-22" draggable="true"></div>
            <div className="inventory-item" id="inventory-23" draggable="true"></div>
            <div className="inventory-item" id="inventory-24" draggable="true"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default App;
