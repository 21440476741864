import * as THREE from 'three';
// import * as THREE from 'https://cdn.jsdelivr.net/npm/three@0.118.1/build/three.module.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
// import {FBXLoader} from 'https://cdn.jsdelivr.net/npm/three@0.118.1/examples/jsm/loaders/FBXLoader.js';
import { entity_manager } from './entity-manager.js';
import { entity } from './entity.js';
import { finite_state_machine } from './finite-state-machine.js';
import { player_input } from './player-input.js';
import Joystick from './player-joystick.js';
import { player_state } from './player-state.js';
import { spatial_grid_controller } from './spatial-grid-controller.js';
import { spatial_hash_grid } from './spatial-hash-grid.js';

export const player_entity = (() => {

  class CharacterFSM extends finite_state_machine.FiniteStateMachine {
    constructor(proxy) {
      super();
      this._proxy = proxy;
      this._Init();
    } 
  
    _Init() {
      this._AddState('idle', player_state.IdleState);
      this._AddState('walk', player_state.WalkState);
      this._AddState('run', player_state.RunState);
      this._AddState('attack', player_state.AttackState);
      this._AddState('death', player_state.DeathState);
    }
  };
  
  class BasicCharacterControllerProxy {
    constructor(animations) {
      this._animations = animations;
    }
  
    get animations() {
      return this._animations;
    }
  };
  

  class JoystickController extends entity.Component {
    constructor(params) {
      super();
      // this._parent = parent;
      this._Init(params);
      // console.log(this._parent)
      this._init();
      this._params = params;
      const circle = document.createElement("div");
      circle.style.cssText = "position: absolute; bottom:70px; width:80px; height:80px; background:rgba(126, 126, 126, 0.5); border:#444 solid medium; border-radius:50%; left:50%; transform:translateX(-50%);";
      const thumb = document.createElement("div");
      thumb.style.cssText = "position: absolute; left: 20px; top: 20px; width: 35px; height: 35px; border-radius: 50%; background: #fff;";
      circle.appendChild(thumb);
      document.body.appendChild(circle);
      this.domElement = thumb;
      this.maxRadius = 40;
		  this.maxRadiusSquared = this.maxRadius * this.maxRadius;
		  this.origin = { left:this.domElement.offsetLeft, top:this.domElement.offsetTop };
		  this.rotationDamping = 0.06;
      this.moveDamping = 0.01;     
      if (this.domElement !== undefined || this.domElement_1 !== undefined){
        const joystick = this;
        if ('ontouchstart' in window){
          this.domElement.addEventListener('touchstart', function(evt){ evt.preventDefault(); joystick.Tap(evt); evt.stopPropagation();});
        }else{
          this.domElement.addEventListener('mousedown', function(evt){ evt.preventDefault(); joystick.Tap(evt); evt.stopPropagation();});
        }
        // this._params = params; 
      }
    }

    _init() {
      this._keys = {
        forward: false,
        backward: false,
        left: false,
        right: false,
        space: false,
        shift: false,
      }
    }
    
    GetMousePosition(evt){
      let clientX = evt.targetTouches ? evt.targetTouches[0].pageX : evt.clientX;
      let clientY = evt.targetTouches ? evt.targetTouches[0].pageY : evt.clientY;
      return { x:clientX, y:clientY };
    }
    
    Tap(evt){
      evt = evt || window.event;
      this._keys.forward = true;
      this._keys.backward = true;
      this._keys.left = true;
      this._keys.right = true;
      this._keys.space = true;
      this._keys.shift = true;
      // get the mouse cursor position at startup:
      this.offset = this.GetMousePosition(evt);
      const joystick = this;
      if ('ontouchstart' in window){
        document.ontouchmove = function(evt){ joystick.Move(evt)};
        document.ontouchend =  function(evt){ joystick.Up(evt)};
      }else{
        document.onmousemove = function(evt){ joystick.Move(evt)};
        document.onmouseup = function(evt){ joystick.Up(evt)};
      }
    }
    
    Move(evt){
      evt = evt || window.event;
      this._keys.forward = true;
      this._keys.backward = true;
      this._keys.left = true;
      this._keys.right = true;
      this._keys.space = true;
      this._keys.shift = true;
      const mouse = this.GetMousePosition(evt);
      // calculate the new cursor position:
      let left = mouse.x - this.offset.x;
      let top = mouse.y - this.offset.y;
      //this.offset = mouse;
      
      const sqMag = left*left + top*top;
      if (sqMag>this.maxRadiusSquared){
        //Only use sqrt if essential
        const magnitude = Math.sqrt(sqMag);
        left /= magnitude;
        top /= magnitude;
        left *= this.maxRadius;
        top *= this.maxRadius;
      }
      // set the element's new position:
      this.domElement.style.top = `${top + this.domElement.clientHeight/2}px`;
      this.domElement.style.left = `${left + this.domElement.clientWidth/2}px`;
      
      this.forward_1 = -(top - this.origin.top + this.domElement.clientHeight/2)/this.maxRadius;
      this.turn_1 = (left - this.origin.left + this.domElement.clientWidth/2)/this.maxRadius;
      
      // if (this.onMove!=undefined) this.onMove.call(this.game, this.forward_1, this.turn_1);
    }

    Up(evt){
      if ('ontouchstart' in window){
        document.ontouchmove = null;
        document.ontouchend = null;
      }else{
        document.onmousemove = null;
        document.onmouseup = null;
      }
      this.domElement.style.top = `${this.origin.top}px`;
      this.domElement.style.left = `${this.origin.left}px`;
      this.forward_1 = 0;
      this.turn_1 = 0;
      this._keys.forward = false;
      this._keys.backward = false;
      this._keys.left = false;
      this._keys.right = false;
      this._keys.space = false;
      this._keys.shift = false;
    }

    _Init(params) {
      this._params = params;
      this._decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
      this._acceleration = new THREE.Vector3(1, 0.125, 50.0);
      this._velocity = new THREE.Vector3(0, 0, 0);
      this._position = new THREE.Vector3();
      // this._input = this.GetComponent('Joystick')
      // this._input = new Joystick(params);
      // console.log(this._input)
      // this._grid = new spatial_hash_grid.SpatialHashGrid([[-1000, -1000], [1000, 1000]], [100, 100]);
      // this._spatial = new spatial_grid_controller.SpatialGridController({grid: this._grid});
 
      this._animations = {};
      this._stateMachine = new CharacterFSM(
          new BasicCharacterControllerProxy(this._animations));
  
      this._LoadModels();
    }

    InitComponent() {
      this._RegisterHandler('health.death', (m) => { this._OnDeath(m); });
    }

    _OnDeath(msg) {
      this._stateMachine.SetState('death');
    }

    _LoadModels() {
      const loader = new FBXLoader();
      loader.setPath('./character/');
      loader.load('Swat.fbx', (fbx) => {
        this._target = fbx;
        this._target.scale.setScalar(0.1);
        this._params.scene.add(this._target);
  
        // this._bones = {};

        // for (let b of this._target.children[1].skeleton.bones) {
        //   this._bones[b.name] = b;
        // }

        this._target.traverse(c => {
          c.castShadow = true;
          c.receiveShadow = true;
          if (c.material && c.material.map) {
            c.material.map.encoding = THREE.sRGBEncoding;
          }
        });

        this.Broadcast({
            topic: 'load.character',
            model: this._target,
            bones: this._bones,
        });

        this._mixer = new THREE.AnimationMixer(this._target);

        const _OnLoad = (animName, anim) => {
          const clip = anim.animations[0];
          const action = this._mixer.clipAction(clip);
    
          this._animations[animName] = {
            clip: clip,
            action: action,
          };
        };

        this._manager = new THREE.LoadingManager();
        this._manager.onLoad = () => {
          this._stateMachine.SetState('idle');
        };
  
        const loader = new FBXLoader(this._manager);
        loader.setPath('./character/');
        loader.load('swat-idle.fbx', (a) => { _OnLoad('idle', a); });
        loader.load('swat-run.fbx', (a) => { _OnLoad('run', a); });
        loader.load('swat-walk.fbx', (a) => { _OnLoad('walk', a); });
        loader.load('swat-fire.fbx', (a) => { _OnLoad('attack', a); });
        loader.load('swat-die.fbx', (a) => { _OnLoad('death', a); });
      });
    }

    _FindIntersections(pos) {
      const _IsAlive = (c) => {
        const h = c.entity.GetComponent('HealthComponent');
        if (!h) {
          return true;
        }
        return h._health > 0;
      };

      const grid = this.GetComponent('SpatialGridController');
      const nearby = grid.FindNearbyEntities(5).filter(e => _IsAlive(e));
      // const collisions = [];

      for (let i = 0; i < nearby.length; ++i) {
        const e = nearby[i].entity;
        const d = ((pos.x - e._position.x) ** 2 + (pos.z - e._position.z) ** 2) ** 0.5;

        // HARDCODED
        // if (d <= 4) {
        //   collisions.push(nearby[i].entity);
        // }
      }
      return;
      // collisions;
    }
    
    Update(timeInSeconds) {
      if (!this._stateMachine._currentState) {
        return;
      }

      // this._entityManager = new entity_manager.EntityManager();
      // this._grid = new spatial_hash_grid.SpatialHashGrid([[-1000, -1000], [1000, 1000]], [100, 100]);
      // const player = new entity.Entity();
      // player.AddComponent(new player_input.Joystick(this._params));
      // player.AddComponent(new spatial_grid_controller.SpatialGridController({grid: this._grid}));
      // this._entityManager.Add(this._player, 'player');
      // const input = Joystick;
      // const input = this.GetComponent('n')
      const input = this
      // const input = new Joystick(this._params);
      // console.log(this)
      // console.log(input)
      // console.log(this)
      // const input = this._parent._components.Joystick
      // this.input = this._parent._components.Joystick
      // console.log(this._params)
      // console.log(input)


      // const parent = 
      // console.log(this.input)
      this._stateMachine.Update(timeInSeconds, input);

      if (this._mixer) {
        this._mixer.update(timeInSeconds);
      }

      // HARDCODED
      if (this._stateMachine._currentState._action) {
        this.Broadcast({
          topic: 'player.action',
          action: this._stateMachine._currentState.Name,
          time: this._stateMachine._currentState._action.time,
        });
      }

      const currentState = this._stateMachine._currentState;
      if (currentState.Name !== 'walk' &&
          currentState.Name !== 'run' &&
          currentState.Name !== 'idle') {
        return;
      }
    
      const velocity = this._velocity;
      const frameDecceleration = new THREE.Vector3(
          velocity.x * this._decceleration.x,
          velocity.y * this._decceleration.y,
          velocity.z * this._decceleration.z
      );
      frameDecceleration.multiplyScalar(timeInSeconds);
      frameDecceleration.z = Math.sign(frameDecceleration.z) * Math.min(
          Math.abs(frameDecceleration.z), Math.abs(velocity.z));
  
      velocity.add(frameDecceleration);
  
      const controlObject = this._target;
      const _Q = new THREE.Quaternion();
      const _A = new THREE.Vector3();
      const _R = controlObject.quaternion.clone();
  
      const acc = this._acceleration.clone();
       
      controlObject.quaternion.copy(_R);
  
      const oldPosition = new THREE.Vector3();
      oldPosition.copy(controlObject.position);
  
      const forward = new THREE.Vector3(0, 0, 1);
      forward.applyQuaternion(controlObject.quaternion);
      forward.normalize();
  
      const sideways = new THREE.Vector3(1, 0, 0);
      sideways.applyQuaternion(controlObject.quaternion);
      sideways.normalize();
  
      sideways.multiplyScalar(velocity.x * timeInSeconds);
      forward.multiplyScalar(velocity.z * timeInSeconds);
  
      const pos = controlObject.position.clone();
      
      // if (input.forward_1 || input.turn_1) {
        // if (input.forward_1 > 0) {
        if (this.forward_1 > 0) {
          velocity.z += 1.5 * acc.z * timeInSeconds;
          // forward.multiplyScalar(input.forward_1 * 100 * timeInSeconds);
          forward.multiplyScalar(this.forward_1 * 100 * timeInSeconds);
        }
        // if (input.forward_1 < 0) {
        if (this.forward_1 < 0) {
        velocity.z -= 1.5 * acc.z * timeInSeconds;
          // forward.multiplyScalar(input.forward_1 * -100 * timeInSeconds);
          forward.multiplyScalar(this.forward_1 * -100 * timeInSeconds);
        }
        // if (input.turn_1 > 0) {
        if (this.turn_1 > 0) {
          _A.set(0, 1, 0);
          // _Q.setFromAxisAngle(_A, 10.0 * -input.turn_1 * timeInSeconds * this._acceleration.y);
          _Q.setFromAxisAngle(_A, 10.0 * -this.turn_1 * timeInSeconds * this._acceleration.y);
          _R.multiply(_Q);
          controlObject.quaternion.copy(_R);
          this._parent.SetQuaternion(-this._target.quaternion);
          // sideways.multiplyScalar(input.turn_1 * 100 * timeInSeconds);
        }
        // if (input.turn_1 < 0) {
        if (this.turn_1 < 0) {
          _A.set(0, 1, 0);
          // _Q.setFromAxisAngle(_A, 10.0 * -input.turn_1 * timeInSeconds * this._acceleration.y);
          _Q.setFromAxisAngle(_A, 10.0 * -this.turn_1 * timeInSeconds * this._acceleration.y);
          _R.multiply(_Q);
          controlObject.quaternion.copy(_R);
          this._parent.SetQuaternion(this._target.quaternion);
          // sideways.multiplyScalar(input.turn_1 * -100 * timeInSeconds);
        }
        // console.log(this.forward_1, this.turn_1)
      // }

      pos.add(forward);
      pos.add(sideways);

      // const collisions = this._FindIntersections(pos);
      // if (collisions.length > 0) {
      //   return;
      // }

      controlObject.position.copy(pos);
      this._position.copy(pos);
  
      this._parent.SetPosition(this._position);
      this._parent.SetQuaternion(this._target.quaternion);
    }
  };
  
  return {
      BasicCharacterControllerProxy: BasicCharacterControllerProxy,
      // BasicCharacterController: BasicCharacterController,
      JoystickController: JoystickController,
  };

})();