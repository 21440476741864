import {entity} from './entity.js'

class Joystick extends entity.Component {
    constructor(params) {
      super();
      this._init();
      this._params = params;
      const circle = document.createElement("div");
      circle.style.cssText = "position: absolute; bottom:70px; width:80px; height:80px; background:rgba(126, 126, 126, 0.5); border:#444 solid medium; border-radius:50%; left:50%; transform:translateX(-50%);";
      const thumb = document.createElement("div");
      thumb.style.cssText = "position: absolute; left: 20px; top: 20px; width: 35px; height: 35px; border-radius: 50%; background: #fff;";
      circle.appendChild(thumb);
      document.body.appendChild(circle);
      this.domElement = thumb;
      this.maxRadius = 40;
		  this.maxRadiusSquared = this.maxRadius * this.maxRadius;
		  this.origin = { left:this.domElement.offsetLeft, top:this.domElement.offsetTop };
		  this.rotationDamping = 0.06;
      this.moveDamping = 0.01;     
      if (this.domElement !== undefined || this.domElement_1 !== undefined){
        const joystick = this;
        if ('ontouchstart' in window){
          this.domElement.addEventListener('touchstart', function(evt){ evt.preventDefault(); joystick.Tap(evt); evt.stopPropagation();});
        }else{
          this.domElement.addEventListener('mousedown', function(evt){ evt.preventDefault(); joystick.Tap(evt); evt.stopPropagation();});
        }
        // this._params = params; 
      }
    }

    _init() {
      this._keys = {
        forward: false,
        backward: false,
        left: false,
        right: false,
        space: false,
        shift: false,
      }
    }

    GetMousePosition(evt){
      let clientX = evt.targetTouches ? evt.targetTouches[0].pageX : evt.clientX;
      let clientY = evt.targetTouches ? evt.targetTouches[0].pageY : evt.clientY;
      return { x:clientX, y:clientY };
    }
    
    Tap(evt){
      evt = evt || window.event;
      this._keys.forward = true;
      this._keys.backward = true;
      this._keys.left = true;
      this._keys.right = true;
      this._keys.space = true;
      this._keys.shift = true;
      // get the mouse cursor position at startup:
      this.offset = this.GetMousePosition(evt);
      const joystick = this;
      if ('ontouchstart' in window){
        document.ontouchmove = function(evt){ joystick.Move(evt)};
        document.ontouchend =  function(evt){ joystick.Up(evt)};
      }else{
        document.onmousemove = function(evt){ joystick.Move(evt)};
        document.onmouseup = function(evt){ joystick.Up(evt)};
      }
    }
    
    Move(evt){
      evt = evt || window.event;
      this._keys.forward = true;
      this._keys.backward = true;
      this._keys.left = true;
      this._keys.right = true;
      this._keys.space = true;
      this._keys.shift = true;
      const mouse = this.GetMousePosition(evt);
      // calculate the new cursor position:
      let left = mouse.x - this.offset.x;
      let top = mouse.y - this.offset.y;
      //this.offset = mouse;
      
      const sqMag = left*left + top*top;
      if (sqMag>this.maxRadiusSquared){
        //Only use sqrt if essential
        const magnitude = Math.sqrt(sqMag);
        left /= magnitude;
        top /= magnitude;
        left *= this.maxRadius;
        top *= this.maxRadius;
      }
      // set the element's new position:
      this.domElement.style.top = `${top + this.domElement.clientHeight/2}px`;
      this.domElement.style.left = `${left + this.domElement.clientWidth/2}px`;
      
      this.forward_1 = -(top - this.origin.top + this.domElement.clientHeight/2)/this.maxRadius;
      this.turn_1 = (left - this.origin.left + this.domElement.clientWidth/2)/this.maxRadius;
      
      // if (this.onMove!=undefined) this.onMove.call(this.game, this.forward_1, this.turn_1);
    }

    Up(evt){
      if ('ontouchstart' in window){
        document.ontouchmove = null;
        document.ontouchend = null;
      }else{
        document.onmousemove = null;
        document.onmouseup = null;
      }
      this.domElement.style.top = `${this.origin.top}px`;
      this.domElement.style.left = `${this.origin.left}px`;
      this.forward_1 = 0;
      this.turn_1 = 0;
      this._keys.forward = false;
      this._keys.backward = false;
      this._keys.left = false;
      this._keys.right = false;
      this._keys.space = false;
      this._keys.shift = false;
    }

  };

export default Joystick;